body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Share Tech Mono", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.body-container {
  margin-left: 275px;
}
@media only screen and (max-width: 900px) {
  .body-container {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 1040px) {
  .body-container {
    margin-left: 0px;
  }
  .ipad-iframe-container .resize {
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
