$small: 400px;
$medium: 900px;
a {
  text-decoration: none; // Elimina el subrayado
  &:hover {
    color: inherit; // Mantén el color original en hover
    text-decoration: none; // Elimina el subrayado en hover
    font-family: 'Righteous', cursive;
  }
}
.text-logo{
  color:#353535

}
.sidebar {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 275px;
  background-color: #fff;

  &__logo-container {
    height: 120px;
    display: grid;
    place-items: center;
    font-family: 'Righteous', cursive;

    // margin-top: 30px;
  }
  &__logo {
    display: grid;
    place-items: center top;
    height: 10px;
    font-size: 2.3rem;
    font-weight: 700;
  }
  &__subtitle {
    display: grid;
    place-items: center;
    height: 10px;
    font-size: 0.8rem;
    font-weight: 200;
    font-style: italic;
  }
  &__nav-items {
    margin-top: 6em;
  }
  &__menu {
    position: relative;

    &__item {
      display: flex;
      align-items: center;
      place-content: flex-start;
      padding: 0.2rem 2rem;
      font-size: 1.25rem;
      font-weight: 500;
      color: #555555;
      transition: color 0.3s ease-in-out;
      text-decoration: none !important;

      &.active {
        color: #000;
      }

      &__icon {
        margin-right: 1rem;

        i {
          font-size: 1.75rem;
        }
      }
    }

    &__indicator {
      position: absolute;
      top: 0;
      left: 50%;
      width: calc(100% - 40px);
      border-radius: 10px;
      background-color: #fcec63;
      z-index: -1;
      transform: translateX(-50%);
      transition: 0.3s ease-in-out;
      color: #000;
    }
  }
}
@media only screen and (max-width: $medium) {
  .sidebar {
  display: contents;

    position: fixed;
    top: 0;
    left: 0;
    height: 31vh;
    width: 106vw;
  }
}
@media only screen and (max-width: $small) {
  .sidebar {
  display: contents;

    position: fixed;
    top: 0;
    left: 0;
    height: 31vh;
    width: 106vw;


    &__logo-container {
      height: 36px;
      display: grid;
      place-items: center;
      // margin-top: 30px;
    }
    &__logo {
      display: grid;
      place-items: center top;
      height: 10px;
      font-size: 2.3rem;
      font-weight: 700;
    }
    &__subtitle {
      display: grid;
      place-items: center;
      height: 10px;
      font-size: 0.8rem;
      font-weight: 200;
      font-style: italic;
    }
    &__nav-items {
      margin-top: 6em;
    }
    &__menu {
      position: relative;

      &__item {
        display: flex;
        align-items: center;
        place-content: flex-start;
        padding: 0.2rem 2rem;
        font-size: 1.25rem;
        font-weight: 500;
        color: #555555;
        transition: color 0.3s ease-in-out;
        text-decoration: none !important;

        &.active {
          color: #000;
        }

        &__icon {
          margin-right: 1rem;

          i {
            font-size: 1.75rem;
          }
        }
      }

      &__indicator {
        position: absolute;
        top: 0;
        left: 50%;
        width: calc(100% - 40px);
        border-radius: 10px;
        background-color: #fcec63;
        z-index: -1;
        transform: translateX(-50%);
        transition: 0.3s ease-in-out;
        color: #000;
      }
    }
  }
}
