h1 {
  font-weight: bold;
  text-decoration: none;
}
.date {
  color: #ccc;
  font-weight: 300;
  margin: 6px 0;
}
.poem-body {
  color: #000;
  font-weight: 300;
}
.porcentaje-descuento{
  font-size: 1.2em;
  font-weight: 900;
  color: red;
}
.precio-descuento{
  font-size: 1em;
  font-weight: 400;
  color: black;
  text-decoration: line-through;
}
.vendido{
  font-size: 1.2em;
  color: red;
  font-weight: 900;
}
.fondo {
  border: 1px solid #000;
  padding: 10px;
  box-shadow: 8px 10px #000;
  min-height: 50px;
}
br {
  margin: 0%;
}
ul.fotos {
  display: flex;
  list-style:none;
}
img.foto {
  height: 100vh;
  text-decoration: none;
}

.ver-mas{
  display: none;
}
.desktop-info{
  display: block;
  position: relative;
  bottom: 1.5em;
}

strong, em{
  background-color: white;
}


.arriba{
  position: relative;
  bottom: 200px;
}

@media only screen and (max-width: 900px) {
  img.foto {
    height: 100vh;
    text-decoration: none;
  }
}
@media only screen and (max-width: 1040px) {
  .arriba{
    position: relative;
    bottom: 0;
  }
  .ver-mas{
    display: block;
  }
  .desktop-info{
    display: none;
  }
  ul.fotos {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    align-items: center;  
  }
  li {
    flex: 0 0 auto;
    position: relative;
    justify-content: center;
    background-color: white;
    box-sizing: border-box;
    /* padding: 10px 10px 25px; */
    padding: 0px 0px 5px; 
    /* margin: 5px 5px 0 0; */
    user-select: none;
    transition: background-color 0.5s;
  }
  img.foto{
    max-width:100vw;
    height: auto;
  }
  .ipad-iframe-container .resize {
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
  }
}