body{
  overflow-x:scroll;
  overflow-y:hidden;
}
@media only screen and (max-width: 1040px) {
  body{
    overflow-x:hidden;
    overflow-y:scroll;
  }
}
.heading {
  text-decoration: none;
  font-weight: bold;
  color: black;
}

.subtitle{
  text-decoration: underline #E13D43;

}

h2{
  color: #000;
  font-weight: bold;
}
.date{
  color: #ccc;
  font-weight: 300;
  margin: 6px 0;
}
.poem-body{
  color: #000;
  font-weight: 300;
}
.fondo{
  border: 1px solid #000;
  padding: 10px;
  box-shadow: 8px 10px #000;
  min-height: 50px;
}
.nav-item{
  text-decoration: none !important;
}
br{
  margin: 0%;
}
.social-container {
  padding: 10px 25px;
}

a.social {
  margin: 0 0.5rem;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-2px);
  color: #FCEC63;
}

a.icon {
  color: #000;
}

.container-horizontal{
  overflow-x: none;

}